import baseComponents from "../base/index";
import coursePlayer from "./course";
import mine from "./mine";
import examColumn from "./onStudy";
import other from "./other";
import special from "./special";

const components = [...coursePlayer, ...mine, ...examColumn, ...other, ...baseComponents, ...special];

function install(Vue) {
  components.forEach(it => {
    Vue.component(it.name, it);
    if (!window.$components["nc"]) {
      window.$components["nc"] = {};
    }
    window.$components["nc"][it.name] = it;
  });
}

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export { install };

export default install;
