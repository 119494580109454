<template>
  <header class="dsf-college-home-header" :style="{ 'min-width': mainWidth + 20 + 'px' }">
    <div class="dsf-college-home-header-main" :style="{ width: mainWidth + 'px' }">
      <div class="dsf-college-home-header-site">
        <div class="logoBox">
          <a :href="indexUrl">
            <img class="logo" :src="$logo | imgFormat" alt="" />
          </a>
        </div>
        <div class="header-user">
          <home-search :placeholder="collegeRoot.searchPlaceholder" :search="collegeRoot.doSearch" @search="collegeRoot.$emit('search', $event)" />
          <!-- <template v-if="isLogin && dsf.cookies.get('user_name')"> -->
          <template v-if="isLogin && userName">
            <div class="header-user-icon" :style="{ 'background-image': `url(${$replace(defaultHeader)})` }">
              <img v-show="showIcon" :src="initHeadIcon | imgFormat" @error="headLoadError" @load="headLoadSuccess" />
            </div>
            <div v-if="!dropdownConfig.length" class="username">
              <!-- 欢迎您,{{ dsf.cookies.get("user_name") }} -->
              欢迎您,{{ userName }}
              <i v-if="dropdownConfig.length" :class="[showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou']" class="iconfont" />
            </div>
            <el-dropdown v-else :show-timeout="0" placement="bottom" @visible-change="showDropdown = $event" @command="handleCommand">
              <div class="username">
                <!-- 欢迎您,{{ dsf.cookies.get("user_name") }} -->
                欢迎您,{{ userName }}
                <i v-if="dropdownConfig.length" :class="[showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou']" class="iconfont" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in dropdownConfig" :key="(item.key || '') + '__' + index" :command="index">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="shopping-cars" v-if="dsf.config.setting_nc_tenement_is_shop == 1" @click="toShoppingCars"><i class="shopping-icon"></i></div>
          </template>
          <template v-else>
            <template v-for="(bt, index) in customBts">
              <i :key="index" v-if="index" class="iconfont icon-vertical_line"></i>
              <a :key="index" class="link" @click="bt.handler()">{{ bt.name }}</a>
            </template>
          </template>
        </div>
      </div>
      <!-- v-show="showMenu" -->
      <div class="dsf-college-home-header-menu">
        <div class="dsf-college-home-header-menu-bar" :style="barStyle"></div>
        <div class="dsf-college-home-header-menu-list">
          <template v-for="(menu, index) in menuList">
            <div
              ref="menu"
              class="dsf-college-home-header-menu-item"
              :key="menu.menu_id"
              :class="{ active: activeMenuIndex === index }"
              @click="collegeRoot._clickBefore(menuItemClick, menu, index)"
            >
              {{ menu.menu_name }}
              <span class="isjb" v-if="(menu.menu_name == '学习中心' && content)">{{ content }}</span>
              <span class="isjb" v-else-if="menu.menu_name == '比武练兵' && lbCount > 0">{{ lbCount }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import menuUtils from "../utils";
import HomeSearch from "./homeSearch";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "DsfNcCollegeHomeHeader",
  inject: ["homeRoot", "collegeRoot"],
  components: {
    HomeSearch,
  },
  props: {
    mainWidth: {
      type: Number,
      default: 1200,
    },
    dropdownConfig: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否只响应最后一级菜单的点击事件
    lastLevelClick: {
      type: Boolean,
      default: false,
    },
    // 未登录状态下自定义按钮
    customBts: {
      type: Array,
      default() {
        return [];
      },
    },
    // 未匹配到菜单时，是否自动隐藏菜单
    isAutoHideMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: 0,
      lbCount: 0,
      headIcon: "",
      defaultHeader: dsf.config.setting_public_user_default_card_header,
      showIcon: false,
      showDropdown: false,
      /************/
      activeMenuIndex: -1,
      barStyle: { display: "none" },
      // loginState: dsf.getCookie("isLogin"),
    };
  },
  computed: {
    ...mapGetters(["userName"]),
    indexUrl() {
      return location.href.replace(/#.*/, "");
    },
    $logo() {
      return this.$replace(this.collegeRoot.logoUrl);
    },
    menuList() {
      console.log(this.homeRoot.$menuList);
      return this.homeRoot.$menuList;
    },
    menuIndex() {
      return this.homeRoot.menuIndex || [];
    },
    showMenu() {
      if (!this.isAutoHideMenu) return false;
      return this.activeMenuIndex > -1 && this.activeMenuIndex < this.menuList.length;
    },
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
    initHeadIcon() {
      let headIcon = dsf.getCookie("photoRelativePath");
      headIcon = decodeURIComponent(headIcon) || "";
      return headIcon;
    },
  },
  watch: {
    menuIndex: {
      handler(to) {
        let index = to.length ? to[0] : -1;
        if (!this.isMounted) {
          this._activeMenuIndex = index;
        } else {
          this.activeMenuIndex = index;
          this.updateBarStyle();
        }
      },
      deep: true,
    },
  },
  created() {
    if(this.isLogin){
      this.getContent();
      this.getLbCount();
    }
  
    let userName = dsf.getCookie("user_name");
    this.$store.state.userName = userName || "";
  },
  mounted() {
    this.isMounted = true;
    if (this._activeMenuIndex !== undefined) {
      this.activeMenuIndex = this._activeMenuIndex;
      this.updateBarStyle();
    }
  },
  methods: {
    getLbCount(){
      let that = this;
      this.$http
        .get("/meta/list/count", {
            pageNum: 1,
            pageSize: 10,
            query: JSON.stringify({"searchValue":""}),
            order: JSON.stringify([]),
            filter: JSON.stringify([]),
            namespace: 'nc.exam',
            pageName: 'tournamentNumList'}
          )
        .done(function (res) {
          if (res.success) {
            that.lbCount = res.data.count || 0;
          } else {
            dsf.layer.message(res.message || "请求异常", false);
          }
        })
        .error(function (err) {
          //错误
          dsf.layer.message((err && err.message) || "请求异常", false);
        })
        .always(function () {
        });
    },
    // 初始化头像，从cookie中获取
    // initHeadIcon() {
    //   const headIcon = dsf.getCookie("photoRelativePath");
    //   if (headIcon) {
    //     this.headIcon = decodeURIComponent(headIcon);
    //   }
    // },
    getContent() {
      var _this = this;
      var loading = dsf.layer.loading();
      //xxx为请求地址，key为参数名，value参数值
      this.$http
        .get("/inc/nc/learn/year/underwayTaskCnt")
        .done(function (res) {
          if (res.success) {
            //查询成功
            _this.content = _this.formatNumber(res.data["classCnt"] + res.data["courseCnt"] + res.data["examCnt"] + res.data["questionnaireCnt"]);
          } else {
            dsf.layer.message(res.message || "请求异常", false);
          }
        })
        .error(function (err) {
          //错误
          dsf.layer.message((err && err.message) || "请求异常", false);
        })
        .always(function () {
          //不管成功失败都会执行（请求，比如404，200）
          dsf.layer.closeLoading(loading);
        });
    },
    formatNumber(num) {
      return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + "k" : num >= 1e4 ? (num / 1e4).toFixed(1) + "w" : num;
    },
    // 头像加载成功时
    headLoadSuccess() {
      this.showIcon = true;
    },
    // 头像加载失败时
    headLoadError() {
      this.showIcon = false;
    },
    // 用户头像下的菜单点击
    handleCommand(index) {
      const item = this.dropdownConfig[+index];
      this.$emit("dropdown-click", item);
    },
    updateBarStyle() {
      let l = this.menuList.length;
      if (this.activeMenuIndex < 0 || this.activeMenuIndex >= l) {
        this.barStyle = { display: "none" };
        return;
      }
      this.$nextTick(() => {
        let activeMenu = this.$refs.menu?.[this.activeMenuIndex];
        if (!activeMenu) {
          this.barStyle = { display: "none" };
        } else {
          let left = activeMenu.offsetLeft + activeMenu.offsetWidth / 2;
          this.barStyle = {
            transform: `translate(calc(${left}px - 50%), 0) scale(.5)`,
          };
        }
      });
    },
    menuItemClick(item, index) {
      if (!dsf.getToken()) return;
      if (!item.menu_url) {
        dsf.layer.message("暂未开放", false);
        return false;
      }
      if (item.menu_opentype_value != 2) {
        this.activeMenuIndex = index;
      }

      if (this.lastLevelClick) {
        if ((!item.children || !item.children.length) && item.menu_url) {
          menuUtils.openPage.call(this.homeRoot, item);
        }
      } else {
        menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
      }
    },
    toShoppingCars() {
      const path = dsf.config.setting_public_shoppingcars_url;
      path && dsf.layer.openWindow(path);
    },
  },
};
</script>
