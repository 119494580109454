import "../dsf_config";

//全局样式
import "../assets/styles/pc/dsfStyle.scss";
console.log("进入程序了：", new Date().getTime())
//注入本地元数据
import "../metadata";
//注入入本地api
import "../api";
//引入本项目所有pc组件
// import vConsole from "vconsole";
import pcComponent from "./components";
import checkLoginState from "../common/checkLoginState";
import toExamPage from "../common/toExamPage";
import ddPlatform from "./common/ddPlatform";
import App from "./App.vue";
import store from "../store";
import router from "./router";
import i18n from "../i18n";

import themesConfig from "_project/assets/styles/common";
Vue.use(pcComponent);
// import VConsole from "vconsole";
// const vConsole = new VConsole();
// Vue.prototype.$vConsole = new vConsole();
let EventBus = new Vue();
//注入WEBAPI
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus;
    },
  },
});

dsf.init.initPlatform(Vue);
dsf.init.globalMixin(Vue);

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.prototype.$checkLogin = checkLoginState;
Vue.prototype.$toExamPage = toExamPage;
Vue.prototype.$ddPlatform = ddPlatform;
// 登录页地址处理
let loginUrl = dsf.config.setting_public_pc_login_page;

router.beforeEach((to, from, next) => {
  console.log(to);
  //dingding和govDingding不展示
  const ua = navigator.userAgent;
  var browser = {
    versions: (function () {
      // 浏览器版本信息
      return {
        trident: ua.indexOf("Trident") > -1, // IE浏览器 trident内核
        presto: ua.indexOf("Presto") > -1, // opera浏览器 presto内核
        webKit: ua.indexOf("AppleWebKit") > -1, // chrome safari浏览器 webkit内核
        gecko: ua.indexOf("Gecko") > -1 && ua.indexOf("KHTML") == -1, //firefox浏览器 gecko内核
        mobile: !!ua.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
        ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
        android: ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1, // android终端或UC浏览器
        iPad: ua.indexOf("iPad") > -1, //iPad终端
        webApp: ua.indexOf("Safari") == -1, //是否web应用程序，没有头部与底部
      };
    })(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase(), // 文档语言
  };
  console.log("location.pathname", location.pathname == "/");
  if (location.pathname == "/") {
    if (ua.match(/MicroMessenger/i) == "MicroMessenger") {
      // 判断是否是移动设备打开
      location.href = "/mobile-index.html?appid=nc.page.app.manager#/mobile/nc/page/app/home/mobile-index";
    }
    // if (browser.versions.mobile) {
    //   // 在微信中打开
    //   location.href = "/mobile-index.html?appid=nc.page.app.manager#/mobile/nc/page/app/home/mobile-index";
    // }

    // if (ua.match(/WeiBo/i) == "Weibo") {
    //   // 在新浪微博客户端打开
    // }
    // if (ua.match(/QQ/i) == "QQ") {
    //   // 在QQ端打开
    // }

    if (browser.versions.ios) {
      // 在IOS浏览器打开
      location.href = "/mobile-index.html?appid=nc.page.app.manager#/mobile/nc/page/app/home/mobile-index";
    }

    if (browser.versions.android) {
      // 在安卓浏览器打开
      loginUrl = "/mobile-index.html?appid=nc.page.app.manager#/mobile/ncLogin";
      location.href = "/mobile-index.html?appid=nc.page.app.manager#/mobile/nc/page/app/home/mobile-index";
      // alert(JSON.stringify(location));
    } else {
      // 否则就是PC浏览器打开
      loginUrl = "/ncIndex.html#/pc/nc/pagehome/index";
      location.href = "/ncIndex.html#/pc/nc/pagehome/index";
    }
  }

  if (ua.toLowerCase().indexOf("dingtalk") === -1) {
    if (dsf.getCookie("reset_pwd")) {
      //弱密码强制修改密码
      dsf.layer.openDialog({
        title: "当前密码较弱，请修改密码",
        width: "600px",
        height: "390px",
        showClose: false,
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        params: {
          path: "/pc/nc/page/pc/login/changePwd?hideCancelButton=1",
        },
      });
    }
  }
  //通过路由的title修改页面标题
  document.title = to.meta.title || dsf.config.setting_public_logo_title || "";
  next();
});
router.afterEach((to, from) => {
  if ((to.meta.needLogin || to.fullPath == "/") && !dsf.getToken()) {
    // 非登录页，且需要登录，跳转到登录页面
    let url = loginUrl + (to.fullPath !== "/" ? "?from=" + encodeURIComponent(location.href) : "");
    location.replace(url);
    return;
  }
  //优先判断路由是否存在
});
new Vue({
  mixins: [dsf.$systemGlobalSetting(themesConfig)],
  store,
  router,
  i18n,
  created() {
    //监听window窗体变化后，递归组件执行组件的resize函数
    dsf.resizeDetector();
    if (dsf.config.setting_nc_tenement_silence === "on") {
      document.body.style.filter = "grayscale(100%)";
    }
    //修复IE前进后退路由变化没效果的问题
    if ("-ms-scroll-limit" in document.documentElement.style && "-ms-ime-align" in document.documentElement.style) {
      window.addEventListener(
        "hashchange",
        () => {
          var currentPath = window.location.hash.slice(1);
          if (router.path !== currentPath) {
            router.replace(currentPath);
          }
        },
        false
      );
    }
  },
  render: h => h(App),
}).$mount("#app");
