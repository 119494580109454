<template>
  <div class="dsf_cardItemByExam" v-if="visible">
    <div class="content">
      <div class="item-wrap" v-if="isDesign">
        <div class="img-box pointer" :style="{ width: imgWidth, height: imgHeight, margin: imgMargin }">
          <el-image :src="src | imgFormat" fit="cover"></el-image>
          <p class="examTxt">考试</p>
          <p class="learnTime">{{ formatterDate("2020.3.4", "yyyy.mm.dd") }}~{{ formatterDate("2020.3.15", "yyyy.mm.dd") }}</p>
        </div>
        <div class="info" :style="{ width: `calc(96% - ${imgWidth})` }">
          <p class="title ell">关于《XXXX》学习的考试</p>
          <div class="detail">
            <div class="detail-cell-1">
              <span>考试总分：100分</span>
              <span>及格分数：60分</span>
            </div>
          </div>
        </div>
        <div class="score">
          <p class="score-num">78分</p>
          <p class="txt">最高成绩</p>
        </div>
      </div>
      <div class="item-wrap" v-else @click="_clickBefore(goDetail)">
        <div class="img-box pointer" :style="{ width: imgWidth, height: imgHeight, margin: imgMargin }">
          <!-- <el-image :src="data['cover'] | imgFormat" fit="cover"></el-image> -->
          <!-- <el-image :src="data.cover | imgFormat" fit="cover">
            <div slot="error" class="image-slot">
              <img :src="dsf.config.setting_nc_image_default_img" alt="" />
            </div>
          </el-image> -->
          <dsf-nc-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
          <p class="examTxt">考试</p>
          <p class="learnTime" v-if="data['startTime']">{{ formatterDate(data["startTime"], "yyyy.mm.dd") }}~{{ formatterDate(data["endTime"], "yyyy.mm.dd") }}</p>
        </div>
        <div class="info" :style="{ width: `calc(96% - ${imgWidth})` }">
          <p class="title ell pointer">关于《{{ data["title"] || "" }}》学习的考试</p>
          <div class="detail">
            <div class="detail-cell-1">
              <span>考试总分：{{ data["total"] || 0 }}分</span>
              <span>及格分数：{{ data["pass"] || 0 }}分</span>
            </div>
          <!--   <div class="detail-cell-2">
              <span>考试次数：{{ data["examNum"].includes("无限") ? data["examNum"] : (data["examNum"] || 0) + "次" }}</span>
              <span>已考次数：{{ data["already"] || 0 }}次</span>
              <span>剩余次数：{{ data["residue"].includes("无限") ? data["residue"] : (data["residue"] || 0) + "次" }}</span>
            </div> -->
          </div>
        </div>
        <div class="score" v-if="data['heightScore']">
          <p class="score-num">{{ data["heightScore"] || 0 }}分</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcCardItemByLb",
  ctrlCaption: "考试卡片-比武练兵",
  mixins: [$mixins.card],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    imgWidth: {
      type: String,
      default: "270px",
    },
    imgHeight: {
      type: String,
      default: "150px",
    },
    imgMargin: {
      type: String,
      default: "0 20px 0 0",
    },
    clickState: {
      type: String,
      default: "1",
    },
    keysMap: {
      type: Array,
      default: () => {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "title", to: "title", type: "text", desc: "考试名称" },
          { from: "startTime", to: "startTime", type: "text", desc: "开始时间" },
          { from: "endTime", to: "endTime", type: "text", desc: "结束时间" },
          { from: "total", to: "total", type: "text", desc: "总分" },
          { from: "pass", to: "pass", type: "text", desc: "及格分数" },
          { from: "examNum", to: "examNum", type: "text", desc: "考试次数" },
          { from: "already", to: "already", type: "text", desc: "已考次数" },
          { from: "residue", to: "residue", type: "text", desc: "剩余次数" },
          { from: "heightScore", to: "heightScore", type: "text", desc: "最高成绩" },
          { from: "isDo", to: "isDo", type: "text", desc: "考试是否可用" },
          { from: "businessId", to: "businessId", type: "text", desc: "获取考试businessId" },
          { from: "business_type", to: "business_type", type: "text", desc: "business_type" },
          { from: "exam_set_id", to: "exam_set_id", type: "text", desc: "exam_set_id" },
          { from: "id", to: "id", type: "text", desc: "id" },
        ];
      },
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  components: {},
  methods: {
    formatterDate(time, format) {
      return time ? dsf.date.format(time, !format ? "yyyy-mm-dd hh:ii" : format) : "";
    },
    async goDetail() {
      // let url = this.$replace(this.detailUrl, this.data);
      // console.log(url);
      // if (this.data["residue"].includes("无限")) {
      //   let examUrl = await this.toExamDetail(this.data);
      //   window.open(examUrl);
      // } else if (this.data["residue"] != 0) {
      //   let examUrl = await this.toExamDetail(this.data);
      //   window.open(examUrl);
      // } else if (this.data["residue"] == 0) {
      //   this.dsf.layer.message("考试次数已用完", false);
      // } else if (this.data["isDo"] == 0) {
      //   this.dsf.layer.message("该考试不可用", false);
      // } else {
      //   this.dsf.layer.message("进入考试异常", false);
      // }
      let data = this.data;
      /**
       * @param businessType 专题班考核 2 单元考试 2 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: data["id"],
        businessId: data["businessId"],
        businessType: data["business_type"],
        examSetId: data["exam_set_id"],
        deviceType: "pc",
      };
      this.$toExamPage(params, this.clickState);
    },
    appendExam(data) {
      return new Promise((resolve, reject) => {
        const loading = dsf.layer.loading();
        this.dsf.http
          .post(this.examAppendUrl, { examId: data["id"] }, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              resolve(res);
            } else {
              this.dsf.layer.message(res.message || "获取考试异常", false);
              reject();
            }
          })
          .error(response => {
            this.dsf.layer.message(response.message || "获取考试异常", false);
            reject();
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      });
    },
    toExamDetail(data) {
      return new Promise((resolve, reject) => {
        const loading = dsf.layer.loading();
        /**
         * @param businessType 专题班1 考核2 单元考试1 任务考试 3
         * @param deviceType pc mobile
         */
        let params = {
          examId: data["id"],
          businessId: data["businessId"],
          businessType: data["business_type"],
          examSetId: data["exam_set_id"],
          deviceType: "pc",
        };
        this.dsf.http
          .get("ncexam/api/getExamUrl", params, $$webRoot.nc)
          .done(res => {
            if (res.success) {
              resolve(res.data);
            } else {
              reject();
              dsf.layer.message(res.message || "获取考试异常", false);
            }
          })
          .error(err => {
            reject();
            dsf.layer.message(err.message || "获取考试异常", false);
          })
          .always(function () {
            dsf.layer.closeLoading(loading);
          });
      });
    },
    // getExamUrl(data) {
    //   return new Promise((resolve, reject) => {
    //     const loading = dsf.layer.loading();
    //     this.dsf.http
    //       .get(this.examUrl, { examId: data["id"], deviceType: "pc", businessId: data["businessId"], entryType: 2 }, $$webRoot.nc)
    //       .done(res => {
    //         if (res.success) {
    //           resolve(res.data);
    //         } else {
    //           reject();
    //           this.dsf.layer.message(res.message || "获取考试异常", false);
    //         }
    //       })
    //       .error(response => {
    //         reject();
    //         this.dsf.layer.message(response.message || "获取考试异常", false);
    //       })
    //       .always(function () {
    //         dsf.layer.closeLoading(loading);
    //       });
    //   });
    // },
  },
});
</script>
