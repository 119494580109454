<template>
  <div class="dsf-nc-college-home full-view">
    <home-header :main-width="mainWidth" :dropdown-config="dropdownConfig" :custom-bts="customBts" @dropdown-click="$emit('dropdown-click', $event)" />
    <section class="dsf-nc-college-home-section" :style="{ 'min-width': mainWidth + 20 + 'px' }">
      <div class="min-view" style="min-height: calc(100vh - 288px)">
        <router-view />
      </div>
    </section>
    <footer class="dsf-nc-college-home-footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import HomeHeader from "./homeHeader.vue";

export default {
  name: "DsfCollegeAliHome",
  components: {
    HomeHeader,
  },
  provide() {
    return {
      collegeRoot: this,
    };
  },
  props: {
    mainWidth: {
      type: [Number, String],
      default: "100%",
    },
    dropdownConfig: {
      type: Array,
      default() {
        return [];
      },
    },
    logoUrl: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      required: true,
    },
    // 未登录状态下自定义按钮
    customBts: {
      type: Array,
      default() {
        return [];
      },
    },
    clickBefore: {
      type: Function,
      required: false,
    },
    doSearch: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      height: "auto",
    };
  },
  created() {},
  methods: {
    loaded() {
      // this.loading = false;
      this.$nextTick(() => {
        this.height = "auto";
        this.show = true;
      });
    },
    leaveHandler(el) {
      this.height = el.offsetHeight + "px";
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.clickBefore?.(...params);
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
  },
};
</script>
