<template>
  <div v-if="isDesign" class="ds-nc-card-course">
    <div class="cover">
      <dsf-image />
      <span class="state">课程</span>
      <div class="bottom">
        <div>
          <i class="iconfont icon-xueshi"></i>
          0.2学时
        </div>
        <div>
          <i class="iconfont icon-shijian"></i>
          00:22:15
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <span style="color: #ff3600; font-weight: bold; margin-right: 5px">NEW</span>
        <span>课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题</span>
      </div>
    </div>
    <div class="footer">
      <span>张三</span>
      <span>
        <i class="iconfont icon-guankanbeifen4" style="font-size: 0.9em"></i>
        1234
      </span>
    </div>
  </div>
  <div v-else-if="objData" class="ds-nc-card-course" :title="objData['nc_courses_page_homesource._name']" @click="_clickBefore(click)" :style="{ width: imgWidth }">
    <div class="cover" :style="{ width: imgWidth, height: imgHeight }">
      <dsf-image :src="objData['nc_courses_page_homesource.cover'] | imgFormat" :error-src="defaultSrc" />
      <span class="state" v-if="objData['state']">{{ objData["state"] }}</span>
      <div class="bottom" v-if="objData['hours'] || objData['durtion']">
        <div>
          <i class="iconfont icon-xueshi"></i>
          {{ objData["hours"] }}学时
        </div>
        <div>
          <i class="iconfont icon-shijian"></i>
          {{ objData["durtion"] }}
          <span class="static_time">00:22:15</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <span v-if="objData['nc_courses_page_homesource.is_new']" style="color: #ff3600; font-weight: bold; margin-right: 5px">NEW</span>
        <span>{{ objData["nc_courses_page_homesource._name"] }}</span>
      </div>
    </div>
    <div class="footer">
      <span class="ellipsis" :title="objData['nc_courses_page_homesource.teacher_text'] ? objData['nc_courses_page_homesource.teacher_text'].replace(/\^/g, '、') : ''">
        {{ objData["nc_courses_page_homesource.teacher_text"] ? objData["nc_courses_page_homesource.teacher_text"].replace(/\^/g, "、") : "" }}
      </span>
      <span>
        <i class="iconfont icon-guankanbeifen4" style="font-size: 0.9em"></i>
        {{ objData["nc_courses_page_homesource.view_totle"] || 0 }}
      </span>
    </div>
  </div>
  <div v-else class="ds-nc-card-course" :title="data['title']" @click="_clickBefore(click)" :style="{ width: imgWidth }">
    <div class="cover" :style="{ width: imgWidth, height: imgHeight }">
      <dsf-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
      <span class="state" v-if="data['state']">{{ data["state"] }}</span>
      <span class="state_compulsory_value" v-if="data['compulsory_value'] == 1">必学</span>
      <div class="bottom" v-if="data['hours'] || data['durtion']">
        <div>
          <i class="iconfont icon-xueshi"></i>
          {{ data["hours"] }}学时
        </div>
        <div>
          <i class="iconfont icon-shijian"></i>
          {{ data["durtion"] }}
          <span class="static_time">00:22:15</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <span v-if="data['isNew']" style="color: #ff3600; font-weight: bold; margin-right: 5px">NEW</span>
        <span>{{ data["title"] }}</span>
      </div>
    </div>
    <div class="footer">
      <span class="ellipsis" style="width: 100px" :title="data['teacher'] ? data['teacher'].replace(/\^/g, '、') : ''">
        {{ data["teacher"] ? data["teacher"].replace(/\^/g, "、") : "" }}
      </span>
      <div>
        <span :style="{color: setStateColor}" class="couser_state">{{data['finished']}}</span>
        <span>
          <i class="iconfont icon-guankanbeifen4" style="font-size: 0.9em"></i>
          {{ data["views"] || 0 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: 'DsfNcCourseCardNew',
  ctrlCaption: '课程卡片-新',
  mixins: [$mixins.card],
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: 'cover', to: 'cover', type: 'text', desc: '封面' },
          { from: 'state', to: 'state', type: 'text', desc: '类型' },
          { from: 'finished', to: 'finished', type: 'text', desc: '课程状态' },
          { from: 'compulsory_value', to: 'compulsory_value', type: 'text', desc: '是否必学' },
          { from: 'durtion', to: 'durtion', type: 'text', desc: '时长' },
          { from: 'hours', to: 'hours', type: 'text', desc: '学时' },
          { from: 'isNew', to: 'isNew', type: 'boolean', desc: '标签' },
          { from: 'title', to: 'title', type: 'text', desc: '课题' },
          { from: 'teacher', to: 'teacher', type: 'text', desc: '授课教师' },
          { from: 'views', to: 'views', type: 'text|number', desc: '浏览量' }
        ];
      }
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null
    },
    objData: {
      type: Object,
      default: null
    },
    imgWidth: {
      type: String,
      default: ''
    },
    imgHeight: {
      type: String,
      default: ''
    }
  },
  computed:{
    setStateColor(){
      let colorObj = {
        "已学习": "#7ac728",
        "未学习": "#da3433",
        "学习中": "#e8a357",
      }
      return colorObj[this.data['finished']] || "#000";
    },
  },
  created(){

  },
  methods: {
    click() {
      if (this.$listeners.click) {
        this.$dispatch('click', this.objData);
      } else {
        if (!this.clickConfig) return;
        let { url, state } = this.clickConfig;
        url = this.$replace(url, this.objData);
        this.$open({ url, state });
        this.clickAfter();
      }
    }
  }
});
</script>
