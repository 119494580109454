import VueRouter from "vue-router";
import Login from "../views/Login";
import ErrorView from "../views/Error";
import DdLogin from "../views/DdLogin.vue";
import Welcome from "../views/Welcome.vue";
Vue.use(VueRouter);
const routes = [
  //注入平台路由
  ...$platformRouter.pc,
  {
    path: "/pc/login",
    meta: {
      title: "登录",
      needLogin: false,
    },
    component: Login,
  },
  {
    path: "/pc/ddLogin",
    meta: {
      title: "钉钉",
      needLogin: false,
    },
    component: DdLogin,
  },
  {
    path: "/pc/welcome",
    meta: {
      title: "欢迎使用学习邦",
      needLogin: false,
    },
    component: Welcome,
  },
  //强制匹配404页面
  {
    path: "*",
    component: ErrorView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (document.querySelector(".dsf-college-home")) {
    document.querySelector(".dsf-college-home").scrollTo({
      top: 0,
    });
  }
  next();
});
dsf.init.initRouter(router, "pc");

export default router;