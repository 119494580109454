<template>
  <div class="full-view">
    <dsf-home v-if="pageSuffix == 'index'" :menu-list="menuList">
      <dsf-default-home-header slot="header" :dropdown-config="dropdownConfig" @dropdown-click="dropdownClick">
        <template #logo>
          <span @click="toMainPage" v-if="logoName.length && !logoImg">{{ logoName }}</span>
          <img :src="logoImg | imgFormat" v-if="logoImg" @error="logoError" class="logo-img" @click="toMainPage" />
        </template>
      </dsf-default-home-header>
      <dsf-default-home-aside-menu slot="left" position="left" />
      <router-view fit style="min-height: 100%"></router-view>
      <dsf-personalization :show.sync="personalizationShow"></dsf-personalization>
    </dsf-home>
    <dsf-home v-else-if="pageSuffix == 'index1'" :menu-list="menuList">
      <dsf-template1-home-header slot="header" :dropdown-config="dropdownConfig" last-level-click @dropdown-click="dropdownClick" />
      <dsf-default-home-breadcrumb slot="breadcrumb" />
      <dsf-default-home-footer slot="footer" text="技术支持：上海梦创双杨数据科技股份有限公司" />
      <router-view fit></router-view>
      <dsf-personalization :show.sync="personalizationShow"></dsf-personalization>
    </dsf-home>
    <dsf-home v-else-if="pageSuffix == 'ncIndex'" :menu-list="menuList">
      <dsf-nc-college-home
        :dropdown-config="dropdownConfig"
        @dropdown-click="dropdownClick"
        :logo-url="mainLogo"
        :custom-bts="[{ name: '登录', handler: loginHandler }]"
        :click-before="menuClickBefore"
        :doSearch="searchSubmit"
        ref="colleageHomeHeader"
        is-auto-hide-menu
      >
        <template #footer>
          <dsf-view-part path="/pc/nc/pagefooter/ncFooter"></dsf-view-part>
        </template>
      </dsf-nc-college-home>
      <dsf-personalization :show.sync="personalizationShow"></dsf-personalization>
      <dsf-nc-pc-system-announcement :show.sync="systemAnnouncementShow" :content="systemAnnouncementContent" :title="systemAnnouncementTitle"></dsf-nc-pc-system-announcement>
    </dsf-home>
    <dsf-home v-else-if="pageSuffix == 'aliIndex'" :menu-list="menuList">
      <dsf-college-ali-home
        :dropdown-config="dropdownConfig"
        @dropdown-click="dropdownClick"
        :logo-url="mainLogo"
        :custom-bts="[{ name: '登录', handler: loginHandler }]"
        :click-before="menuClickBefore"
        :doSearch="searchSubmit"
        ref="colleageHomeHeader"
      >
        <template #footer>
          <dsf-college-ali-footer />
        </template>
      </dsf-college-ali-home>
      <dsf-personalization :show.sync="personalizationShow"></dsf-personalization>
      <dsf-nc-pc-system-announcement :show.sync="systemAnnouncementShow" :content="systemAnnouncementContent" :title="systemAnnouncementTitle"></dsf-nc-pc-system-announcement>
    </dsf-home>
    <dsf-home v-else-if="pageSuffix == 'xcIndex'" :menu-list="menuList">
      <dsf-college-xc-home
        :dropdown-config="dropdownConfig"
        @dropdown-click="dropdownClick"
        :logo-url="mainLogo"
        :custom-bts="[{ name: '登录', handler: loginHandler }]"
        :click-before="menuClickBefore"
        :doSearch="searchSubmit"
        ref="colleageHomeHeader"
      >
        <template #footer>
          <dsf-college-xc-footer />
        </template>
      </dsf-college-xc-home>
      <dsf-personalization :show.sync="personalizationShow"></dsf-personalization>
      <dsf-nc-pc-system-announcement :show.sync="systemAnnouncementShow" :content="systemAnnouncementContent" :title="systemAnnouncementTitle"></dsf-nc-pc-system-announcement>
    </dsf-home>
    <dsf-home v-else-if="pageSuffix == 'zxqyIndex'" :menu-list="menuList">
      <dsf-college-zxqy-home
        :dropdown-config="dropdownConfig"
        @dropdown-click="dropdownClick"
        :logo-url="mainLogo"
        :custom-bts="[{ name: '登录', handler: loginHandlerOtherSystem }]"
        :click-before="menuClickOtherSystem"
        :doSearch="searchSubmit"
      ></dsf-college-zxqy-home>
      <dsf-personalization :show.sync="personalizationShow"></dsf-personalization>
      <dsf-nc-pc-system-announcement :show.sync="systemAnnouncementShow" :content="systemAnnouncementContent" :title="systemAnnouncementTitle"></dsf-nc-pc-system-announcement>
    </dsf-home>
    <router-view v-else class="page-head-font-fixed"></router-view>
  </div>
</template>

<script>
import DsfNcCollegeHome from "../home/ncHome/home.vue";
import DsfCollegeAliHome from "../home/aliHome/home.vue";
import DsfCollegeAliFooter from "../home/aliHome/footer.vue";
import DsfCollegeXcHome from "../home/xcHome/home.vue";
import DsfCollegeXcFooter from "../home/xcHome/footer.vue";
import DsfCollegeZxqyHome from "../home/zxqyHome/home.vue";
export default {
  name: "Home",
  components: {
    DsfNcCollegeHome,
    DsfCollegeAliHome,
    DsfCollegeAliFooter,
    DsfCollegeXcHome,
    DsfCollegeXcFooter,
    DsfCollegeZxqyHome,
  },
  props: {
    pageSuffix: {
      type: String,
      default: "else",
    },
  },
  data() {
    return {
      logoName: dsf.config.setting_public_logo_title || "网络学院",
      logoImg: "/nc/saas/setting/logo?key=setting_nc_tenement_pc_admin_logo&code=" + location.pathname.split("/")[1],
      mainLogo: "/nc/saas/setting/logo?key=setting_nc_tenement_pc_logo&code=" + location.pathname.split("/")[1],
      showLogo: true,
      indexUrl: dsf.config.setting_public_pc_login_page,
      ncMainPageUrl: dsf.config.setting_nc_tenement_index_url,
      personalizationShow: false,
      systemAnnouncementShow: false,
      /**
       * **用户头像下的菜单** <br/>
       *  **name**: {String} _必须_ 显示文字 <br/>
       *  **key**: {String | Number} _非必须_ 菜单标识<br/>
       *  -----以下属性任意存在一个------ <br/>
       *  **url**: {String} _非必须_ 如果存在则跳转页面 <br/>
       *  **openUrl**: {String} _非必须_ 如果存在则新开页面 <br/>
       *  **handler**: {String} _非必须_ 如果存在则调用对应方法
       */
      dropdownConfig: [
        // { name: '个人信息', key: 'personInfo', url: '' },
        // { name: '我的收藏', key: 'myCollect' },
        // { name: '我的红色慕课', key: 'myClass' },
        // { name: '我的积分', key: 'myIntegral' },
        // { name: '修改密码', key: 'alterPassword' },
        // { name: '切换为学员', key: 'changeRole' },
        {
          name: "个性化设置",
          key: "personalization",
          handler: "personalizationSetting",
        },
        {
          name: "个人中心",
          key: "userCenter",
          handler: "userCenterSetting",
        },
      ],
      menuList: [],
      systemAnnouncementTitle: "",
      systemAnnouncementContent: "",
      token: dsf.getToken(),
    };
  },
  created() {
    if (this.pageSuffix != "else") {
      getMenuTree.call(this);
    }
    const ua = navigator.userAgent;
    if (ua.toLowerCase().indexOf("dingtalk") == -1) {
      this.dropdownConfig.push({ name: "退出", key: "loginOut", handler: "loginOut" });
    }
    if (this.pageSuffix === "zxqyIndex") {
      this.dropdownConfig.shift();
    }

    if (dsf.getCookie("isLogin") && ua.toLowerCase().indexOf("dingtalk") == -1 && dsf.config.setting_load_global_interface) this.initUserRole();
  },
  mounted() {
    if (this.token && dsf.config.setting_load_global_interface) this.loginPopupNotice();
    // 弹出登录框
    let showLogin = dsf.url.queryString("showLogin");
    if (showLogin) {
      this.$checkLogin();
    }
  },
  methods: {
    loginPopupNotice() {
      this.dsf.http
        .get("/nc/notice/loginPopupNotice", {})
        .done(res => {
          if (res.success) {
            let { data } = res;
            if (data.content) {
              if (sessionStorage.getItem("systemStatus") == 2) {
                this.systemAnnouncementShow = false;
              } else {
                sessionStorage.setItem("systemStatus", 1);
                this.systemAnnouncementShow = true;
                this.systemAnnouncementContent = data.content;
                this.systemAnnouncementTitle = data.title;
              }
            }
          }
        })
        .error(err => {})
        .always(res => {});
    },
    logoError() {
      this.logoImg = null;
    },
    // 用户头像下的菜单点击
    dropdownClick(item) {
      if (item.url) {
        window.location.href = item.url;
      } else if (item.openUrl) {
        window.open(item.openUrl);
      } else if (item.handler) {
        this[item.handler] && this[item.handler](item);
      } else {
        // 其他情况自行处理
      }
    },
    personalizationSetting() {
      this.personalizationShow = true;
    },
    initUserRole() {
      this.dsf.http
        .get("/nc/org/user/unitlist", {})
        .done(({ data, success, message }) => {
          if (success) {
            let list = [];
            _.each(data, item => {
              list.push({
                name: item.name,
                key: item.id,
                id: item.id,
                handler: "changeRoleHandle",
              });
            });
            this.dropdownConfig = [...list, ...this.dropdownConfig];
          } else {
            dsf.layer.message(message || "获取用户身份失败", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "获取用户身份失败", false);
        });
    },
    changeRoleHandle(it) {
      const params = { unitId: it.id };
      this.dsf.http
        .post("/login/switch/unit", params)
        .done(({ data, success, message }) => {
          if (success) {
            window.location.reload();
          } else {
            dsf.layer.message(message || "切换用户身份失败", false);
          }
        })
        .error(err => {
          dsf.layer.message(err.message || "切换用户身份失败", false);
        });
    },
    // 自带方法：退出登录
    loginOut() {
      let that = this;
      dsf.layer
        .confirm("您确定要退出吗?")
        .then(() => {
          // 应后端要求，退出登录cookies由后端清除，若接口异常直接提示报错信息
          this.$webAPI
            .loginOut()
            .then(({ success, message }) => {
              if (success) {
                dsf.storage.session.clear();
                if (this.pageSuffix === "ncIndex" || this.pageSuffix === "aliIndex") {
                  location.href = dsf.config.setting_public_pc_login_page;
                  location.reload(true);
                } else {
                  location.replace(that.indexUrl);
                }
              } else {
                dsf.layer.message(message, false);
              }
            })
            .catch(err => {
              console.error(err);
              dsf.layer.message("退出失败", false);
            });
        })
        .catch(() => {});
    },
    async searchSubmit(keywords) {
      if (keywords) {
        await this.$checkLogin();
        let sourceUrl = dsf.config.setting_public_search_url + "?kw=" + keywords;
        sourceUrl && dsf.layer.openWindow(sourceUrl);
        return true;
      }
    },
    userCenterSetting() {
      const path = dsf.config.setting_public_usercenter_url;
      dsf.layer.openWindow(path);
    },
    loginHandler() {
      this.$checkLogin().then(res => {
        if (res) {
          dsf.http
            .get("/nc/notice/loginPopupNotice", {})
            .done(res => {
              if (!res.success) {
                this.error(res);
              } else {
                let { data } = res;
                if (data.content) {
                  sessionStorage.setItem("systemStatus", 1);
                }
              }
            })
            .error(err => {
              this.error(err);
            })
            .always(res => {});
        }
      });
    },
    loginHandlerOtherSystem() {
      this.$checkLogin(false).then(res => {
        if (res) {
          dsf.http
            .get("/nc/notice/loginPopupNotice", {})
            .done(res => {
              if (!res.success) {
                this.error(res);
              } else {
                let { data } = res;
                if (data.content) {
                  sessionStorage.setItem("systemStatus", 1);
                }
              }
            })
            .error(err => {
              this.error(err);
            })
            .always(res => {});
        }
      });
    },
    async menuClickBefore(it) {
      await this.$checkLogin();
      //在钉钉浏览器时 调用接口跳转到浏览器
      const ua = navigator.userAgent;
      if (ua.toLowerCase().indexOf("dingtalk") > 0 && it.menu_name == "管理中心") {
        const sourceUrl = "/ssologin/getSsoUrl";
        const params = {
          goUrl: it.menu_url,
          goServe: location.origin + dsf.config.webRoot.default,
        };
        this.$http
          .get(sourceUrl, params)
          .done(({ success, data, message }) => {
            if (success) {
              window.open(data);
            } else {
              dsf.layer.pc.message("跳转管理中心:" + message, false);
            }
          })
          .error(error => {
            dsf.httpError(error);
          });

        return false;
      }
    },
    async menuClickOtherSystem() {
      await this.$checkLogin(false);
    },
    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    toMainPage() {
      this.ncMainPageUrl && dsf.layer.openWindow(this.ncMainPageUrl);
    },
  },
  watch: {
    $route(n, o) {
      if (window.$$serverConfig["setting_public_is_home_gray"] == 1) {
        if (n.path == "/pc/nc/pagehome/index") {
          $(".full-view").addClass("isGray");
        } else {
          $(".full-view").removeClass("isGray");
        }
      }
    },
  },
};

/**
 * 请求菜单数据
 */

function getMenuTree() {
  const { pageSuffix } = this;
  const sourceUrl = pageSuffix === "ncIndex" || pageSuffix === "aliIndex" || pageSuffix === "zxqyIndex" ? dsf.config.setting_public_index_menu_src : dsf.config.setting_public_home_menu_src;
  // const isLogin = dsf.getCookie("isLogin");
  let routekey = location.pathname.split("/")[1];
  let params = {};
  if (!routekey.includes(".")) params.routekey = routekey;
  this.$http
    .get(sourceUrl, params)
    .done(({ success, data, message }) => {
      if (success) {
        this.menuList = data;
        //根据设置隐藏商城
        // menu_code==='shop'
       /*  this.menuList.push({menu_code: "lb",
menu_icon: null,
menu_id: "adac74472ea24a9dbbd7aa727ba5a4a8",
menu_name: "比武练兵",
menu_opentype_text: "主窗口",
menu_opentype_value: "1",
menu_pid: "c0a8e8fed53549d88befc2bd97305d8d",
menu_url: "#/pc/nc/exam/tournamentList"}) */
        if (dsf.config.setting_nc_tenement_is_shop != 1) {
          this.menuList.forEach((item, index) => {
            if (item.menu_code === "shop" || (item.menu_url && item.menu_url.includes("onlineshop"))) {
              this.menuList.splice(index, 1);
            }
          });
        }
      } else {
        dsf.layer.pc.message("获取菜单：" + message, false);
      }
    })
    .error(error => {
      dsf.httpError(error);
    });
}
</script>
