<template>
  <div class="dsf_exam_column" v-if="isDesign">
    <div class="item_inner">
      <div class="item-left-img">
        <dsf-image :src="'' | imgFormat" />
        <span class="item-type">考试</span>
        <span class="item-time">2012-10-15 ~ 2012-10-25</span>
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">标题</span>
          <span class="task">任务</span>
        </div>
        <p class="item-exam-rule">
          <span>考试总分: 100分</span>
          <span>及格总分: 60分</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <span>考试次数: 5次</span>
          </li>
          <li>
            <span>已考次数: 1次</span>
          </li>
          <li>
            <span>剩余次数: 4次</span>
          </li>
        </ul>
      </div>
      <div class="rate-wrap">
        <span class="rate">98分</span>
        <span class="rate-text">最高成绩</span>
      </div>
    </div>
  </div>
  <div class="dsf_exam_column" v-else>
    <div class="item_inner" @click.stop="_clickBefore(click)">
      <div class="item-left-img">
        <dsf-image
          :src="data.cover | imgFormat"
          :errorSrc="dsf.config.setting_nc_image_default_img"
        />
        <span class="item-type">{{ data.typeText || "考试" }}</span>
        <span class="item-time"
          >{{ data.start_time }} ~ {{ data.end_time }}</span
        >
      </div>
      <div class="item-right-desc">
        <div class="title">
          <span class="name ellipsis-2">{{ data.name }}</span>
          <span class="task" v-if="data.isTask">任务</span>
        </div>
        <p class="item-exam-rule">
          <span>考试总分: {{ data.score }}分</span>
          <span>及格总分: {{ data.pass_score }}分</span>
        </p>
        <ul class="item-bottom-desc">
          <li>
            <span>考试次数: {{ data.surplus_count }}次</span>
          </li>
          <li>
            <span>已考次数: {{ data.exam_count || 0 }}次</span>
          </li>
          <li>
            <span>剩余次数: {{ data.eRemainTimes || 0 }}次</span>
          </li>
        </ul>
      </div>
      <div class="rate-wrap">
        <span class="rate">{{ data.exam_score || 0 }}分</span>
        <span class="rate-text">最高成绩</span>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcCardExamColumnNew",
  ctrlCaption: "学习任务-考试卡片-新",
  mixins: [$mixins.card],
  props: {
    title: {
      type: String,
      default: "标题",
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "typeText", to: "typeText", type: "boolean", desc: "类型" },
          {
            from: "start_time",
            to: "start_time",
            type: "text",
            desc: "开始时间",
          },
          { from: "end_time", to: "end_time", type: "text", desc: "结束时间" },
          { from: "name", to: "name", type: "text", desc: "标题" },
          { from: "isTask", to: "isTask", type: "text|number", desc: "任务" },
          { from: "score", to: "score", type: "text|number", desc: "考试总分" },
          {
            from: "pass_score",
            to: "pass_score",
            type: "text|number",
            desc: "及格分数",
          },
          {
            from: "surplus_count",
            to: "surplus_count",
            type: "text|number",
            desc: "考试次数",
          },
          {
            from: "exam_count",
            to: "exam_count",
            type: "text|number",
            desc: "已考次数",
          },
          {
            from: "eRemainTimes",
            to: "eRemainTimes",
            type: "text|number",
            desc: "剩余次数",
          },
          {
            from: "exam_score",
            to: "exam_score",
            type: "text|number",
            desc: "考试分数",
          },
          {
            from: "exam_sjgl_id",
            to: "exam_sjgl_id",
            type: "text",
            desc: "考试id",
          },
          {
            from: "businessId",
            to: "businessId",
            type: "text",
            desc: "businessId",
          },
          {
            from: "businessType",
            to: "businessType",
            type: "text",
            desc: "businessType",
          },
          {
            from: "exam_set_id",
            to: "exam_set_id",
            type: "text",
            desc: "exam_set_id",
          },
        ];
      },
    },
    // 报名地址
    examSingupUrl: {
      type: String,
      default: "ncexam/api/getExamUrl",
    },
    //详情地址
    examUrl: {
      type: String,
      default: "ncexam/api/getExamUrl",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    click() {
      let data = this.data;
      /**
       * @param businessType 专题班考核 2 单元考试 2 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: data["exam_sjgl_id"],
        businessId: data["businessId"],
        businessType: data["businessType"],
        examSetId: data["examSetId"],
        deviceType: "pc",
      };
      if (this.$listeners["toDeatail"]) {
        this.$dispatch("toDeatail", params);
      } else {
        this.$toExamPage(params, 2);
      }
    },
    getExamDeatail() {
      const loading = dsf.layer.loading();
      let data = this.data;
      /**
       * @param businessType 专题班考核 2 单元考试 2 任务考试 3
       * @param deviceType pc mobile
       */
      let params = {
        examId: data["exam_sjgl_id"],
        businessId: data["businessId"],
        businessType: data["businessType"],
        examSetId: data["examSetId"],
        deviceType: "pc",
      };
      this.dsf.http
        .get(this.examUrl, params, $$webRoot.nc)
        .done((res) => {
          if (res.success) {
            const path = dsf.url.getWebPath(res.data, $$webRoot.exam);
            dsf.layer.openWindow(path);
          } else {
            dsf.layer.message(res.message || "获取考试异常", false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message || "获取考试异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
  },
});
</script>
